<template>
  <nav
    class="navbar navbar is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <a
        class="navbar-item"
        :href="getFrontEndSkinURL()"
        :title="getFrontEndSkinName()"
      >
        <img
          :src="getFrontEndSkinLogo()"
          :alt="getFrontEndSkinName()"
        >
      </a>

      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarDropdown"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div
      id="navbarDropdown"
      class="navbar-menu"
    >
      <div
        class="navbar-start"
        style="flex-grow: 1; justify-content: center;"
      >
        <router-link
          :to="{name: 'Team'}"
          class="navbar-item is-tab"
          title="Team"
        >
          Team
        </router-link>

        <router-link
          :to="{name: 'DataSources'}"
          class="navbar-item is-tab"
          title="Data Sources"
        >
          Data Sources
        </router-link>

        <router-link
          v-if="userHasPermission('edit_billing')"
          :to="{name: 'Billing'}"
          class="navbar-item is-tab"
          title="Billing"
        >
          Billing
        </router-link>

        <router-link
          :to="{name: 'Stats'}"
          class="navbar-item is-tab"
          title="Stats"
        >
          Stats
        </router-link>

        <a
          v-if="hasFrontEndSkinSupportUrl()"
          :href="getFrontEndSkinSupportUrl()"
          class="navbar-item is-tab"
          title="Contact us for support"
        >
          Support
        </a>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              {{ data.user.email }}
              <img
                :src="data.user.image"
                alt="User Image"
                class="is-circular"
              >
            </a>

            <div class="navbar-dropdown">
              <router-link
                :to="{name: 'Logout'}"
                class="navbar-item"
                title="Log out"
              >
                <i class="fas fa-sign-out-alt" /><span class="margin-left-10">Log Out</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FrontEndSkinMixin from './mixins/FrontEndSkinMixin';

export default {
  name: 'Layout',
  computed: {
    ...mapState('user', ['data']),
  },
  mounted() {
    if (FrontEndSkinMixin.methods.isBusinessUnitTapClicks()) {
      const chatBoxScript = document.createElement('script');
      chatBoxScript.setAttribute('id', 'hs-script-loader');
      chatBoxScript.setAttribute('src', '//js.hs-scripts.com/4664432.js');
      document.head.appendChild(chatBoxScript);
    }
  },
  created() {
    this.getProfile(true);
  },
  methods: {
    ...mapActions('user', ['getProfile']),
  },
};
</script>
