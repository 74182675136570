const ServiceLogoMixin = {
  methods: {
    getServiceLogo(serviceIdentifier) {
      return `img/service-icons/${serviceIdentifier}.svg`;
    },
    getLogo(key) {
      return `img/${key}.svg`
    }
  }
};

export default ServiceLogoMixin;
