const DifferentiationMixin = {
  methods: {
    canLogin() {
      return true;
    },
    canAccessRoles() {
      return true;
    },
  },
};

export default DifferentiationMixin;
