const constants = require('../../server/src/config/constants');

const SKINS = {
  LOCALHOST: {
    hostnames: ['localhost'],
    logo: 'logo-megalytic.png',
    name: 'Megalytic',
    url: 'https://gds.megalytic.com/',
    loginBackground: '/img/login-bg-megalytic.png',
    favicon: 'favicon.ico',
    businessUnit: constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC,
    supportUrl: 'https://megalytic.zendesk.com/hc/en-us/requests/new',
    faqUrl: 'https://support.tapclicks.com/hc/en-us/articles/4408733909019-FAQs-Megalytic-Pricing',
    dataSourceInterestForm: 'https://docs.google.com/forms/d/e/1FAIpQLSfplY5dZIjUroSXQwd0PUntFw6mMqjDCtHcc2xYYgC3-RiM-A/viewform',
    supportEmail: 'gds@megalytic.com',
    gTagId: 'AW-1009761969',
    primaryColor: {
      h: 8,
      s: '96.8%',
      l: '63.3%',
      r: 252,
      g: 96,
      b: 71,
    },
    seriesColors: [
      '#2971a3',
      '#ff5404',
      '#60b552',
      '#a74eb7',
      '#fab250',
      '#38b0ad',
      '#e12c81',
      '#cde000',
      '#b7c701',
      '#6c649b',
      '#6ec4c4',
      '#db679d',
    ],
    plan: {
      accounts: {
        min: 1,
        max: 100,
        default: 20,
        chargeAt: 20,
        price: 3,
      },
      users: {
        min: 1,
        max: 10,
        default: 5,
        chargeAt: 5,
        price: 5,
      }
    },
  },
  [constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC]: {
    hostnames: ['megalytic'],
    logo: 'logo-megalytic.png',
    name: 'Megalytic',
    url: 'https://gds.megalytic.com/',
    enterpriseEmail: 'gds@megalytic.com',
    loginBackground: '/img/login-bg-megalytic.png',
    favicon: 'favicon.ico',
    businessUnit: constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC,
    supportUrl: 'https://megalytic.zendesk.com/hc/en-us/requests/new',
    faqUrl: 'https://support.tapclicks.com/hc/en-us/articles/4408733909019-FAQs-Megalytic-Pricing',
    dataSourceInterestForm: 'https://docs.google.com/forms/d/e/1FAIpQLSfplY5dZIjUroSXQwd0PUntFw6mMqjDCtHcc2xYYgC3-RiM-A/viewform',
    supportEmail: 'gds@megalytic.com',
    gTagId: 'AW-1009761969',
    primaryColor: {
      h: 8,
      s: '96.8%',
      l: '63.3%',
      r: 252,
      g: 96,
      b: 71,
    },
    seriesColors: [
      '#2971a3',
      '#ff5404',
      '#60b552',
      '#a74eb7',
      '#fab250',
      '#38b0ad',
      '#e12c81',
      '#cde000',
      '#b7c701',
      '#6c649b',
      '#6ec4c4',
      '#db679d',
    ],
    plan: {
      accounts: {
        min: 1,
        max: 100,
        default: 20,
        chargeAt: 20,
        price: 3,
      },
      users: {
        min: 1,
        max: 10,
        default: 5,
        chargeAt: 5,
        price: 5,
      }
    },
  },

  [constants.BU_IMPLEMENTATIONS.BU_RAVEN]: {
    hostnames: ['raventools'],
    logo: 'logo-raventools.png',
    name: 'RavenTools',
    url: 'https://gds.raventools.com/',
    enterpriseEmail: 'gds@raventools.com',
    loginBackground: '/img/login-bg-raventools.png',
    favicon: 'favicon-raventools.ico',
    businessUnit: constants.BU_IMPLEMENTATIONS.BU_RAVEN,
    supportUrl: 'https://raven.zendesk.com/hc/en-us/requests/new',
    faqUrl: 'https://support.tapclicks.com/hc/en-us/articles/4408734017307-FAQs-Raven-Tools-Pricing',
    dataSourceInterestForm: 'https://docs.google.com/forms/d/e/1FAIpQLSfhjgMrPFo6cQULzFORNZ4CY4d-1wOpaO4JUo5RMUt8Sh1RBg/viewform',
    supportEmail: 'gds@raventools.com',
    gTagId: 'AW-983050619',
    primaryColor: {
      h: 204,
      s: '62%',
      l: '53%',
      r: 59,
      g: 148,
      b: 209,
    },
    seriesColors: [
      '#3b94d1',
      '#00c0ff',
      '#e96626',
      '#545456',
    ],
    plan: {
      accounts: {
        min: 1,
        max: 100,
        default: 20,
        chargeAt: 20,
        price: 3
      },
      users: {
        min: 1,
        max: 10,
        default: 3,
        chargeAt: 3,
        price: 5
      }
    },
  },

  [constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS]: {
    hostnames: ['tapclicks', 'tapdemo'],
    logo: 'logo-tapclicks.png',
    name: 'TapClicks',
    url: 'https://gds.tapclicks.com/',
    loginBackground: '#0D45A4',
    loginBackgroundImage: '/img/login-bg-tapclicks.png',
    favicon: 'favicon-tapclicks.ico',
    businessUnit: constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS,
    supportUrl: 'https://support.tapclicks.com/hc/en-us/requests/new',
    faqUrl: 'https://support.tapclicks.com/hc/en-us/articles/4413628005019-Pricing-FAQs-TapClicks-for-Google-Data-Studio',
    dataSourceInterestForm: 'https://docs.google.com/forms/d/e/1FAIpQLScAE04yo7ArM3Hm2W2buvXOH3Mb77h7-BX9uoGxrv-O06zXYA/viewform',
    supportEmail: 'datastudio@tapclicks.com',
    gTagId: 'AW-1004923793',
    primaryColor: {
      h: 218,
      s: '85%',
      l: '35%',
      r: 13,
      g: 69,
      b: 164,
    },
    seriesColors: [
      '#2bc791',
      '#099c7f',
      '#ce1d5a',
      '#4a5e75',
      '#4e4f5f',
      '#5a7fc1',
    ],
    // TODO: @Vema Edit the plan object if necessary
    plan: {
      accounts: {
        min: 1,
        max: 100,
        default: 35,
        chargeAt: 35,
        price: 10,
      },
      users: {
        min: 1,
        max: 10,
        default: 1,
        chargeAt: 1,
        price: 10,
      }
    }
  }
};

export default SKINS;
