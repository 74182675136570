import axiosClient from '../../services/axiosClient';
import { bus } from '../../services/bus';
  /* eslint-disable no-undef */
/**
 * Handles API communications with the server app
 * related to logging the user - login / logout / etc
 */
const UserAPI = {
  login(stateOptions) {
    const params = Object.keys(stateOptions).map(key => `${key}=${encodeURIComponent(stateOptions[key])}`).join('&');
    const body = {};
    if((typeof growsumo !== 'undefined') && growsumo.data && growsumo.data.partner_key) {
      body.partnerKey = growsumo.data.partner_key; // Currently no use according to backend code
    }
    return axiosClient
      .post(`/api/home/login?${params}`, body);
  },
  adminSwitch(userData) {
    return axiosClient
      .post('api/home/userSwitch', userData);
  },
  logout() {
    return axiosClient
      .post('/api/home/logout');
  },
  profile(email) {
    return axiosClient
      .get(`/api/team/user?user_email=${email}`);
  },
};

bus.$on('userLogout', () => {
  UserAPI.logout();
});

export default UserAPI;
