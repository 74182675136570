import store from '../store';

const PermissionsHelper = {
  rolePermissions: {},

  userHasPermission(permissionsList) {
    this.rolePermissions = store.getters['user/getPermissions'];

    if (
      typeof this.rolePermissions === 'undefined' ||
      this.rolePermissions === null
    ) {
      return false;
    }

    if (
      Object.keys(this.rolePermissions).length === 0 &&
      this.rolePermissions.constructor === Object
    ) {
      return false;
    }

    if (!Array.isArray(permissionsList)) {
      permissionsList = [permissionsList];
    }

    for (const permission of permissionsList) {
      if (!this.checkPermission(permission)) {
        return false;
      }
    }

    return true;
  },
  checkPermission(permission) {
    return Object.prototype.hasOwnProperty.call(this.rolePermissions, permission) && this.rolePermissions[permission];
  },
};

export default PermissionsHelper;
