import Vue from 'vue';
import Buefy from 'buefy';
import Vue2Filters from 'vue2-filters'
import VueGtm from 'vue-gtm';

// colours, styles, etc
import './assets/fonts/tapicons/scss/nucleo.scss';
import './assets/fonts/serviceicons/css/style.css';
import './styles/_all.scss';

// font awesome
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/solid';

// app starts here
import App from './App';
import store from './store';
import router from './router';
import PermissionsMixin from './mixins/PermissionsMixin';
import AddDataSourceMixin from './mixins/AddDataSourceMixin';
import FrontEndSkinMixin from './mixins/FrontEndSkinMixin';
import ServiceLogoMixin from './mixins/ServiceLogoMixin';

import LoadingStateMixin from './mixins/LoadingStateMixin';
import DifferentiationMixin from './mixins/DifferentiationMixin';
import ConnectorMixin from './mixins/ConnectorMixin';
import PricingMixin from './mixins/PricingMixin';

require('./services/filters');

Vue.config.productionTip = false;
Vue.config.performance = true;

Vue.use(Buefy, {
  defaultIconPack: 'fas',
});

if (store.getters['connectors/getAllowedFromState'].length === 0) {
  store.dispatch('connectors/pullAllConnectors');
}

// using a test credential for GTM until we create on a Tapclicks Account.
Vue.use(VueGtm, {
  id: 'GTM-59X394K',
  vueRouter: router
});

Vue.use(Vue2Filters);

Vue.mixin(PermissionsMixin);
Vue.mixin(AddDataSourceMixin);
Vue.mixin(FrontEndSkinMixin);
Vue.mixin(ServiceLogoMixin);
Vue.mixin(LoadingStateMixin);
Vue.mixin(DifferentiationMixin);
Vue.mixin(PricingMixin);
Vue.mixin(ConnectorMixin);

Vue.prototype.dialogs = [];
Vue.prototype.intervals = {};
Vue.prototype.addModal = function(options) {
  Vue.prototype.dialogs.push(this.$buefy.modal.open(options));
};
Vue.prototype.addDialog = function(options) {
  Vue.prototype.dialogs.push(this.$buefy.dialog.confirm(options));
};
Vue.prototype.addInterval = function(intervalName, callback, timeout) {
  Vue.prototype.intervals[intervalName] = window.setInterval(callback, timeout);
};
Vue.prototype.clearInterval = function(intervalName) {
  if (typeof Vue.prototype.intervals[intervalName] !== 'undefined') {
    window.clearInterval(Vue.prototype.intervals[intervalName]);
  }
};

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>',
});
