import FrontEndSkin from './FrontEndSkin';
import SKINS from '../../config/FrontEndSkinOptions';

const FrontEndSkinHelper = {
  /**
   * @var FrontEndSkin
   */
  skin: null,

  /**
   * @return FrontEndSkin
   */
  getWebsiteSkin() {
    if (this.skin === null) {
      const options = this._getSkinOptions();

      this.skin = new FrontEndSkin(options);

      this._applySkinColours();
    }

    return this.skin;
  },

  _applySkinColours() {
    document.documentElement.style.setProperty('--primary-hue', this.skin.primaryColor.h);
    document.documentElement.style.setProperty('--primary-saturation', this.skin.primaryColor.s);
    document.documentElement.style.setProperty('--primary-luminance', this.skin.primaryColor.l);
    document.documentElement.style.setProperty('--primary-red', this.skin.primaryColor.r);
    document.documentElement.style.setProperty('--primary-green', this.skin.primaryColor.g);
    document.documentElement.style.setProperty('--primary-blue', this.skin.primaryColor.b);

    if (this.skin.hasBackgroundColor()) {
      document.documentElement.style.setProperty('--login-bg', 'none');
      document.documentElement.style.setProperty('--login-color', this.skin.loginBackground);
    } else {
      document.documentElement.style.setProperty('--login-bg', `url(${this.skin.loginBackground})`);
    }

    if (this.skin.hasBackgroundImage()) {
      document.documentElement.style.setProperty('--login-bg', `url(${this.skin.loginBackgroundImage})`);
    }
  },

  _getSkinOptions() {
    // try and match hostname
    for (const key of Object.keys(SKINS)) {
      if (SKINS[key].hostnames.some((hostName) => window.location.hostname.includes(hostName))) {
        return SKINS[key];
      }
    }

    // default to localhost
    return SKINS.LOCALHOST;
  },
};

export default FrontEndSkinHelper;
