import store from '../store';

const ConnectorMixin = {
  methods: {
    getConnectorById(id, getAll = false) {
      return this.getConnectorByField('id', id, getAll);
    },
    getConnectorByField(fieldName, matchAgainst, getAll = false) {
      matchAgainst = String(matchAgainst);

      const allConnectors = this.loadConnectors(getAll);
      for (const connector of allConnectors) {
        if (String(connector[fieldName]) === matchAgainst) {
          return connector;
        }
      }

      return null;
    },
    getAllConnectors() {
      return store.getters['connectors/getAllFromState'];
    },
    loadConnectors(getAll) {
      return getAll ?
        store.getters['connectors/getAllFromState'] : store.getters['connectors/getAllowedFromState'];
    },
  },
};

export default ConnectorMixin;
