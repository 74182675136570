import Vue from 'vue';
import DataSourceAPI from '../../api/modules/DataSource';

/**
 * vuex store representing a list of data sources instance
 */
const dataSourceState = () => ({
  data: {
    sources: [],
  },
});

export default {
  namespaced: true,
  state: dataSourceState,
  getters: {
    getSources: state => state.data.sources,
  },
  actions: {
    getDataSources({state, dispatch}, force = false) {
      return new Promise((resolve) => {
        if (
          (Object.entries(state.data.sources).length === 0 &&
            state.data.sources.constructor === Array) ||
          force
        ) {
          dispatch('getAPISources').then((res) => {
            resolve(res);
          });
        } else {
          resolve(state.data.sources);
        }
      });
    },
    getAPISources: ({ commit }) => {
      return new Promise((resolve) => {
        DataSourceAPI.getSources()
          .then((res) => {
            commit('setSourcesInState', res.data);
            resolve(res.data);
          });
      });
    },
    clearDataSources: ({ commit }) => {
      commit('clearAllSources');
    },
  },
  mutations: {
    clearAllSources(state) {
      Vue.set(state.data, 'sources', []);
    },
    setSourcesInState(state, sources) {
      Vue.set(state.data, 'sources', sources);
    },
  },
};
