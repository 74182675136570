import Vue from 'vue';
import { bus } from '../../services/bus'
import UserAPI from '../../api/modules/User'
import TeamAPI from '../../api/modules/Team';
import { LOGOUT_ACTIONS } from '../../../config/Constants';

/**
 * vuex store representing a user
 */
const userState = () => ({
  data: {
    user: {
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      image: '',
      roleId: '',
      roleName: '',
      permissions: {},
    },
    team: {},
  },
  loggedIn: false,
});

export default {
  namespaced: true,
  state: userState,
  getters: {
    getTeamId: state => state.data.team.id,
    getTeamName: state => state.data.team.name,
    getTeamCreatedAt: state => state.data.team.createdAt,
    getUserRole: state => state.data.user.roleName,
    getUserRoleId: state => state.data.user.roleId,
    getEmail: state => state.data.user.email,
    getUserImage: state => state.data.user.image,
    getUserName: state => {
      return `${state.data.user.firstName} ${state.data.user.lastName}`
    },
    getUser: state => state.data,
    isLoggedIn: state => state.loggedIn === true,
    isSuperAdminUser: state => state.data.user.roleName === 'Super Admin',
    isAdminUser: state => state.data.user.roleName === 'Admin',
    getPermissions: state => state.data.user.permissions,
  },
  actions: {
    getProfile({ state, dispatch }, force = false) {
      return new Promise((resolve) => {
        if (
          (Object.entries(state.data.team).length === 0 &&
            state.data.team.constructor === Object) ||
          force
        ) {
          dispatch('getAPIProfile').then((res) => {
            resolve(res);
          });
        } else {
          resolve(state.data);
        }
      });
    },
    getAPIProfile: ({ commit }) => {
      return new Promise(resolve => {
        TeamAPI.info()
          .then((res) => {
            commit('setProfileInState', res.data);
            commit('setPermissionsInState', res.data.user.permissions);
            resolve(res.data);
          });
      });
    },
    adminSwitch: ({ commit }, userDetails) => UserAPI.adminSwitch(userDetails)
      .then((response) => {
        if (response.data.status === 'Success') {
          commit('setUserInState', response.data.userData);
          return { success: true };
        }

        return { success: false };
      })
      // eslint-disable-next-line
      .catch(error => console.log(error)),
    setUser: ({ commit }, userDetails) => {
      commit('setUserInState', userDetails);
    },
    async logout({ commit, dispatch }) {
      commit('clearUserState');

      // dispatch from the root context so we can access other stores
      for (const action of LOGOUT_ACTIONS) {
        dispatch(action, {}, {root: true});
      }

      bus.$emit('userLogout');
    },
    updateTeamName: ({ commit }, teamName) => {
      commit('updateTeamNameInState', teamName);
    },
    setUserImage({ commit }, userImage) {
      commit('updateUserImageInState', userImage);
    },
    clearUser({ commit }) {
      commit('clearUserState');
    },
  },
  mutations: {
    updateTeamNameInState(state, teamName) {
      Vue.set(state.data.team, 'name', teamName);
    },
    updateUserImageInState(state, userImage) {
      Vue.set(state.data.user, 'image', userImage);
    },
    clearUserState(state) {
      Vue.set(state, 'data', { user: {}, team: {}});
      Vue.set(state, 'loggedIn', false);
    },
    setUserInState(state, userDetails) {
      if (typeof userDetails.name === 'undefined' || !userDetails.name) {
        if (typeof userDetails.first_name !== 'undefined' && typeof userDetails.last_name !== 'undefined') {
          userDetails.name = `${userDetails.first_name} ${userDetails.last_name}`;
        }
      }
      Vue.set(state.data.user, 'name', userDetails.name);
      Vue.set(state.data.user, 'firstName', userDetails.first_name);
      Vue.set(state.data.user, 'lastName', userDetails.last_name);
      Vue.set(state.data.user, 'image', userDetails.image);
      Vue.set(state.data.user, 'email', userDetails.email);
      Vue.set(state, 'loggedIn', true);
    },
    setPermissionsInState(state, permissions) {
      Vue.set(state.data.user, 'permissions', permissions);
    },
    setProfileInState(state, profileDetails) {
      Vue.set(state.data, 'team', {
        name: profileDetails.team.name,
        id: profileDetails.team.id,
        createdAt: profileDetails.team.createdAt,
      });
      Vue.set(state.data.user, 'email', profileDetails.user.email);
      Vue.set(state.data.user, 'roleId', profileDetails.user.roleId);
      Vue.set(state.data.user, 'roleName', profileDetails.user.roleName);
    },
  },
};
