import Vue from 'vue';
import RoleAPI from '../../api/modules/Role';

/**
 * vuex store representing the roles we deal with
 */
const roleState = () => ({
  data: {
    roles: [],
  },
});

export default {
  namespaced: true,
  state: roleState,
  getters: {
    getStoredRoles: state => state.data.roles.roles,
    getIdentifiers: state => state.data.roles.identifiers,
  },
  actions: {
    getRoles({state, dispatch}, force = false) {
      return new Promise((resolve) => {
        if (
          (
            Object.entries(state.data.roles).length === 0 &&
            state.data.roles.constructor === Array
          ) || force
        ) {
          dispatch('getAPIRoles').then((res) => {
            resolve(res);
          });
        } else {
          resolve(state.data.roles);
        }
      });
    },
    getAPIRoles: ({ commit }) => {
      return new Promise((resolve) => {
        RoleAPI.getRoles()
          .then((res) => {
            commit('setRolesInState', res.data);
            resolve(res.data);
          });
      });
    },
    clearRoles: ({ commit }) => {
      commit('clearRoles');
    },
  },
  mutations: {
    setRolesInState(state, roles) {
      Vue.set(state.data, 'roles', roles);
    },
    clearRoles(state) {
      Vue.set(state.data, 'roles', []);
    },
  },
};
