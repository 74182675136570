const PricingMixin = {
  methods: {
    workOutPricing(isAnnual, monthlyPrice, applyDiscount = true) {
      if (!isAnnual) {
        return monthlyPrice;
      }

      const price = monthlyPrice * 12;

      return applyDiscount ? price * 0.8 : price;
    },
    tierPricing(price) {
      if (price === 0) {
        return '';
      }
      return price / 100;
    },
  }
};

export default PricingMixin;
