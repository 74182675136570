import axiosClient from '../../services/axiosClient';

/**
 * Handles API communications with the server app
 * related to logging the user - login / logout / etc
 */
const TeamAPI = {
  info() {
    return axiosClient
      .get('/api/team/info');
  },
  user(user_email) {
    return axiosClient
      .get(`/api/team/user?user_email=${user_email}`);
  },
  roles() {
    return axiosClient
      .get('/api/team/roles');
  },
  updateName(teamData) {
    return axiosClient
      .put('/api/team/info', teamData);
  },
  getUsers() {
    return axiosClient
      .get('/api/team/users');
  },
};

export default TeamAPI;
