import FrontEndSkinHelper from '../services/FrontEndSkinHelper';

const constants = require('../../../server/src/config/constants');

const FrontEndSkinMixin = {
  methods: {
    getFrontEndSkin() {
      return FrontEndSkinHelper.getWebsiteSkin();
    },
    getFrontEndSkinName() {
      return this.getFrontEndSkin().name;
    },
    getFrontEndSkinURL() {
      return this.getFrontEndSkin().url;
    },
    getFrontEndSkinEnterpriseEmail() {
      return this.getFrontEndSkin().enterpriseEmail;
    },
    getFrontEndSkinBusinessUnit() {
      return this.getFrontEndSkin().businessUnit;
    },
    getFrontEndSkinLogo() {
      return `/img/${this.getFrontEndSkin().logo}`;
    },
    getFrontEndSkinSeriesColors() {
      return this.getFrontEndSkin().seriesColors;
    },
    getFrontEndSkinPlanOptions() {
      return this.getFrontEndSkin().planOptions;
    },
    isBusinessUnitRaven() {
      return this.getFrontEndSkin().businessUnit === constants.BU_IMPLEMENTATIONS.BU_RAVEN;
    },
    isBusinessUnitMegalytic() {
      return this.getFrontEndSkin().businessUnit === constants.BU_IMPLEMENTATIONS.BU_MEGALYTIC;
    },
    isBusinessUnitTapClicks() {
      return this.getFrontEndSkin().businessUnit === constants.BU_IMPLEMENTATIONS.BU_TAPCLICKS;
    },
    hasFrontEndSkinSupportUrl() {
      return this.getFrontEndSkinSupportUrl() !== '';
    },
    getFrontEndSkinSupportUrl() {
      return this.getFrontEndSkin().supportUrl;
    },
    getFrontEndSkinSupportEmail() {
      return this.getFrontEndSkin().supportEmail;
    },
    getFrontEndSkinFAQUrl() {
      return this.getFrontEndSkin().faqUrl;
    },
    getFrontEndSkinDataSourceInterestForm() {
      return this.getFrontEndSkin().dataSourceInterestForm;
    },
    getFrontEndSkinGTagId() {
      return this.getFrontEndSkin().gTagId;
    }
  }
};

export default FrontEndSkinMixin;
