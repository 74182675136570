import Vue from 'vue';
import TeamAPI from '../../api/modules/Team';

/**
 * vuex store representing a team
 */
const teamState = () => ({
  data: {
    team: {
      users: [],
    },
  },
});

export default {
  namespaced: true,
  state: teamState,
  actions: {
    getTeamUsers({state, dispatch}, force = false) {
      return new Promise((resolve) => {
        if (state.data.team.users.length === 0 || force) {
          dispatch('getAPITeamUsers').then((res) => {
            resolve({users: res});
          });
        } else {
          resolve(state.data.team);
        }
      });
    },
    getAPITeamUsers: ({ commit }) => {
      return new Promise((resolve) => {
        TeamAPI.getUsers()
          .then((res) => {
            const includingName = [];
            if (res.data.length > 0) {
              for (const userRow of res.data) {
                userRow.fullName = userRow.firstName + userRow.lastName;
                includingName.push(userRow);
              }
            }
            commit('setTeamUsersInState', includingName);
            resolve(includingName);
          });
      });
    },
    clearTeam: ({ commit }) => {
      commit('clearTeam');
    },
  },
  mutations: {
    setTeamUsersInState(state, users) {
      Vue.set(state.data.team, 'users', users);
    },
    clearTeam(state) {
      Vue.set(state.data, 'team', {users: []});
    },
  },
};
