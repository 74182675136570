const { BU_IMPLEMENTATIONS } = require('../../server/src/config/constants');

/**
 * Status for accounts / connections
 */
export const STATUSES = {
  STATUS_ACTIVE: 'active',
  STATUS_ON_TRIAL: 'in_trial',
  STATUS_CANCELLED: 'cancelled',
  STATUS_TRIAL_EXPIRED: 'trial_expired',
  STATUS_NON_RENEWING: 'non_renewing',
};

/**
 * custom events for Google Analytics
 */
export const GA_EVENTS = {
  CONNECTOR_SELECTED: "GDS - CONNECTOR SELECTED",
  CONNECTOR_DESELECTED: "GDS - CONNECTOR DESELECTED",
  ACCOUNTS_CHANGED: "GDS - ACCOUNTS CHANGED",
  USERS_CHANGED: "GDS - USERS CHANGED",
  CONTACT_SUPPORT: "GDS - CONTACT SUPPORT",
  CUSTOMIZE_PLAN: "GDS - CUSTOMIZE PLAN",
  BILLING_PERIOD: "GDS - TOGGLE BILLING PERIOD",
  CHECKOUT: "GDS - PROCEED TO CHECKOUT",
  CANCEL_SUBSCRIPTION: "GDS - CANCEL SUBSCRIPTION"
}

export const GA_CATEGORIES = {
  BILLING: "GDS - BILLING",
  CLICKS: "click"
}

export const FACEBOOK_ADS_IDENTIFIER = 'facebook-ads';


/**
 * Names for intervals that are created on login
 */
export const INTERVALS = {
  LOGIN_CHECK: 'googleLoginCheck',
  REFRESH_TOKEN: 'googleRefreshToken',
};

export const PRICING_MODELS = {
  PRICING_MODEL_TIERED: 'tiered',
  PRICING_MODEL_INDIVIDUAL: 'individual',
};

export const PLANS = {
  PLAN_BASIC: 'basic-plan',
  PLAN_ANNUAL: 'annual-plan',
  RAVEN_V1_PLAN_ANNUAL: 'raven-v1-annual-plan',
  RAVEN_V1_PLAN_BASIC: 'raven-v1-basic-plan',
  TAPCLICKS_V1_PLAN_ANNUAL: 'tapclicks-v1-annual-plan',
  TAPCLICKS_V1_PLAN_BASIC: 'tapclicks-v1-basic-plan',
  PLAN_PREFIX: 'annual',
};

export const ANNUAL_PLAN_FOR_BASIC_PLAN = {
  [PLANS.RAVEN_V1_PLAN_BASIC]: PLANS.RAVEN_V1_PLAN_ANNUAL,
  [PLANS.TAPCLICKS_V1_PLAN_BASIC]: PLANS.TAPCLICKS_V1_PLAN_ANNUAL,
}

export const DISCOUNT_FOR_ANNUAL_PLAN = {
  [PLANS.RAVEN_V1_PLAN_ANNUAL]: 20,
  [PLANS.TAPCLICKS_V1_PLAN_ANNUAL]: 20,
}

export const LIMIT_ADDONS_PREFIX_FOR_PLAN = {
  [PLANS.RAVEN_V1_PLAN_BASIC]: 'raven-v1-',
  [PLANS.RAVEN_V1_PLAN_ANNUAL]: 'raven-v1-',
  [PLANS.TAPCLICKS_V1_PLAN_BASIC]: 'tapclicks-v1-',
  [PLANS.TAPCLICKS_V1_PLAN_ANNUAL]: 'tapclicks-v1-',
}

export const ACTIVE_PLANS = ['basic-plan', 'new-basic-plan', 'annual-plan', PLANS.RAVEN_V1_PLAN_BASIC, PLANS.RAVEN_V1_PLAN_ANNUAL, 
  PLANS.TAPCLICKS_V1_PLAN_BASIC, PLANS.TAPCLICKS_V1_PLAN_ANNUAL];

export const ACTIVE_ANNUAL_PLANS = [
  PLANS.PLAN_ANNUAL,
  PLANS.RAVEN_V1_PLAN_ANNUAL,
  PLANS.TAPCLICKS_V1_PLAN_ANNUAL,
];

// bundle plan discount in $
export const BUNDLE_PLAN_DISCOUNTS = {
  'starter-tier': 10.98,
  'pro-tier': 40.96,
  'annual_starter-tier': 131.76,
  'annual_pro-tier': 491.52
}

export const DEFAULT_PLANS = {
  [BU_IMPLEMENTATIONS.BU_MEGALYTIC]: PLANS.PLAN_BASIC,
  [BU_IMPLEMENTATIONS.BU_RAVEN]: 'starter-tier',
};

export const BETA_CONNECTORS = [
  'mailchimp'
]

/**
 * Actions taken upon logout
 */
export const LOGOUT_ACTIONS = [
  'team/clearTeam',
  'billing/clearAddons',
  'billing/clearPlan',
  'billing/clearPlans',
  'billing/clearInvoices',
  'connectors/clearConnectors',
  'role/clearRoles',
  'dataSource/clearDataSources',
  'app/clearLoadingState',
];

export const DATA_STUDIO_URL = 'https://datastudio.google.com/datasources/create?connectorId=';
