import Vue from 'vue';

const appState = () => ({
  data: {
    isLoading: false,
    version: process.env.PACKAGE_VERSION || '0',
    nextUrl: null,
  },
});

export default {
  namespaced: true,
  state: appState,
  getters: {
    getLoadingState: state => state.data.isLoading,
    getVersion: state => state.data.version,
    getNextUrl: state => state.data.nextUrl,
  },
  actions: {
    setLoadingState({commit}, loadingState) {
      commit('setLoadingStateInState', loadingState);
    },
    clearLoadingState: ({ commit }) => {
      commit('clearLoadingStateInState');
    },
    setNextUrlState({commit}, nextUrl) {
      commit('setNextUrlStateInState', nextUrl);
    },
  },
  mutations: {
    setLoadingStateInState(state, loadingState) {
      Vue.set(state.data, 'isLoading', loadingState);
    },
    clearLoadingStateInState(state) {
      Vue.set(state.data, 'isLoading', false);
    },
    setNextUrlStateInState(state, nextUrl) {
      Vue.set(state.data, 'nextUrl', nextUrl);
    },
  },
};
