import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from "secure-ls";
import UserStore from './stores/User';
import TeamStore from './stores/Team';
import BillingStore from './stores/Billing';
import RoleStore from './stores/Role';
import DataSourceStore from './stores/DataSource';
import AppStore from './stores/App';
import ConnectorStore from './stores/Connectors';

const ls = new SecureLS({ isCompression: false });

const debug = process.env.NODE_ENV !== 'production';
// 12 hours
const EXPIRY = 4.32e+7;

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    user: UserStore,
    team: TeamStore,
    billing: BillingStore,
    role: RoleStore,
    dataSource: DataSourceStore,
    app: AppStore,
    connectors: ConnectorStore,
  },
  strict: debug,
  plugins: [createPersistedState({
    storage: {
      getItem: key => getWithExpiry(key),
      setItem: (key, value) => setWithExpiry(key, value, EXPIRY),
      removeItem: key => ls.remove(key),
    }
  })],
});

// using expiry with local storage:
// https://www.sohamkamani.com/blog/javascript-localstorage-with-ttl-expiry/
function setWithExpiry(key, value, ttl) {
  ls.set(key, JSON.stringify({
    value,
    expiry: new Date().getTime() + ttl,
  }));
}

function getWithExpiry(key) {
  const itemStr = ls.get(key);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);

  if (new Date().getTime() > item.expiry) {
    ls.remove(key);
    return null;
  }
  return item.value;
}

export default store;
