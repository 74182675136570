<template>
  <section
    :class="{'modal-card': isLoginForm}"
    class="padding-top-5 padding-right-5 padding-bottom-5 padding-left-5"
    style="margin-left: 0; margin-right: 0;"
  >
    <div v-if="gdsRedirectUri">
      <div v-if="credentials && credentials.length > 0">
        <aside class="menu">
          <p class="menu-label">
            You are currently logged in with {{ credentials.length }} {{ connector.nameSimple }} account<span v-if="isPlural">s</span>
          </p>
          <ul class="menu-list">
            <li>
              <ul class="menu-list">
                <li
                  v-for="credential in credentials"
                  :key="credential.id"
                >
                  {{ credential.name }} ({{ credential.id }})
                </li>
              </ul>
            </li>
          </ul>
        </aside>

        <b-button
          tag="a"
          :href="gdsRedirectUri"
          class="is-primary button-shadowed margin-top-10"
        >
          Continue with <span v-if="isPlural">these</span><span v-else>this</span> account<span v-if="isPlural">s</span>
        </b-button>

        <p
          class="margin-top-10 margin-bottom-10"
        >
          Or log-in with another account:
        </p>
      </div>
    </div>

    <AuthLogin
      :internal-connector-id="Number(internalConnectorId)"
      :is-public="isPublic"
      :auth-u-r-i="authURI"
      :gds-redirect-uri="gdsRedirectUri"
      :connector-name="connector.nameSimple"
      :connector-u-r-l="connector.url"
    />
  </section>
</template>

<script>
import Vue from 'vue';
import AuthLogin from './AuthLogin';

export default {
  name: 'BaseAuth',
  components: {
    AuthLogin,
  },
  props: {
    internalConnectorId: {
      type: Number,
      required: true,
    },
    authURI: {
      type: String,
      required: true,
    },
    credentials: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    gdsRedirectUri: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    isPublic: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      connector: {
        nameSimple: '',
        url: '',
      },
    };
  },
  computed: {
    isLoginForm() {
      return typeof this.connector.loginFields !== 'undefined';
    },
    isPlural() {
      return this.credentials.length > 1;
    },
  },
  mounted() {
    Vue.set(this, 'connector', this.getConnectorById(this.internalConnectorId, this.isPublic));
  },
};
</script>

<style scoped>

</style>
