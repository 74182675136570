import PermissionsHelper from '../services/PermissionsHelper';

const PermissionsMixin = {
  methods: {
    userHasPermission(permissionString) {
      return PermissionsHelper.userHasPermission(permissionString);
    },
  }
};

export default PermissionsMixin;

