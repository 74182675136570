import Vue from 'vue';
import Router from 'vue-router';
import * as Cookies from 'js-cookie';
import store from '../store';
import PermissionsHelper from '../services/PermissionsHelper';
import FrontEndSkinHelper from '../services/FrontEndSkinHelper';
import { INTERVALS, LOGOUT_ACTIONS } from '../../config/Constants';
import { bus } from '../services/bus';

Vue.use(Router);

const { name } = FrontEndSkinHelper.getWebsiteSkin();
const HTMLTitle = `${name} Google Data Studio Connector`;
const titleSeparator = '| ';

const router = new Router({
  mode: 'history',
  linkActiveClass: 'is-active',
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () =>
        import(/* webpackChunkName: "page-login" */ '../pages/PageLogin'),
      meta: {
        public: true,
      },
    },
    {
      path: '/logout',
      name: 'Logout',
      component: () =>
        import(/* webpackChunkName: "logout" */ '../components/Logout'),
      meta: {
        public: true,
      },
    },
    {
      path: '/auth',
      name: 'Auth',
      component: () =>
        import(/* webpackChunkName: "o-auth-public" */ '../components/OAuthPublic'),
      meta: {
        public: true,
        isCallback: true,
        title: `Add Data Source ${titleSeparator}${HTMLTitle}`,
      },
    },
    {
      path: '/',
      redirect: { name: 'Team' },
    },
    {
      path: '/team',
      name: 'Team',
      component: () =>
      import(/* webpackChunkName: "page-team" */ '../pages/PageTeam'),
      meta: {
        title: `Team ${titleSeparator}${HTMLTitle}`,
      },
    },
    {
      path: '/data-sources',
      name: 'DataSources',
      component: () =>
        import(/* webpackChunkName: "page-data-sources" */ '../pages/PageDataSources'),
      meta: {
        title: `Data Sources ${titleSeparator}${HTMLTitle}`,
      },
    },
    {
      path: '/data-sources/callback',
      name: 'DataSourcesCallback',
      component: () =>
        import(/* webpackChunkName: "data-source-call-back" */ '../components/DataSourceCallback'),
      meta: {
        public: true,
        isCallback: true,
        title: `Add Data Source ${titleSeparator}${HTMLTitle}`,
      },
    },
    {
      path: '/login/callback',
      name: 'LoginCallback',
      component: () =>
        import(/* webpackChunkName: "login-call-back" */ '../components/LoginCallback'),
      meta: {
        public: true,
        isCallback: true,
        title: `Login Callback ${titleSeparator}${HTMLTitle}`,
      },
    },
    {
      path: '/billing',
      name: 'Billing',
      component: () =>
        import(/* webpackChunkName: "page-billing" */ '../pages/PageBilling'),
      meta: {
        title: `Billing ${titleSeparator}${HTMLTitle}`,
        permissions: ['edit_billing'],
      },
    },
    {
      path: '/thankyou',
      name: 'Feedback',
      component: () =>
        import(/* webpackChunkName: "page-feedback" */ '../pages/PageFeedback'),
      meta: {
        title: `Feedback ${titleSeparator}${HTMLTitle}`,
      },
    },
    {
      path: '/stats',
      name: 'Stats',
      component: () =>
        import(/* webpackChunkName: "page-stats" */ '../pages/PageStats'),
      meta: {
        title: `Stats ${titleSeparator}${HTMLTitle}`,
      },
    },
    {
      path: '*',
      name: 'NotFound',
      component: () =>
        import(/* webpackChunkName: "page-not-found" */ '../pages/PageNotFound'),
      meta: {
        public: true,
        title: `Page Not Found ${titleSeparator}${HTMLTitle}`,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || HTMLTitle;
  store.dispatch('app/clearLoadingState');

  if (to.matched.some(record => record.meta.public)) {
    document.body.className = '';
  } else {
    document.body.className = 'has-navbar-fixed-top';
  }

  // handle spoofing a user from the admin panel
  const adminUser = Cookies.get('GDS_ADMIN_USER');
  const adminTeam = Cookies.get('GDS_ADMIN_TEAM');

  if (adminUser && adminTeam) {
    Cookies.remove('GDS_ADMIN_USER');
    Cookies.remove('GDS_ADMIN_TEAM');

    // switch -- if successful set the user and redirect with the data we have
    store.dispatch('user/adminSwitch', {
      user: adminUser,
      teamId: adminTeam,
    }).then((res) => {
      if (res.success) {
        // clear out any existing stores so we get fresh copies with the new data
        const dispatches = ['user/clearUser'];
        for (const action of LOGOUT_ACTIONS) {
          dispatches.push(store.dispatch(action, {}, {root: true}));
        }

        Promise.all(dispatches).then(() => {
          // get the profile for the new user
          store.dispatch('user/getProfile', true, {root: true}).then(() => {
            // refresh to make sure everything is cleared
            // go 0 sometimes ends up reloading the login page
            router.push({name: 'Team'});
          });
        });
      }
    });
  }

  // handle non-public pages
  if (!to.matched.some(record => record.meta.public)) {
    if (!store.getters['user/isLoggedIn']) {
      next({
        path: '/login',
        query: {
          nextUrl: to.name,
        },
      });
    } else {
      // if logged in then remove the login background
      document.querySelector('html').className = '';
      Vue.prototype.clearInterval(INTERVALS.LOGIN_CHECK);
    }
  }

  // if the user is logged in already make sure they're redirected to /team
  // otherwise put the login background on
  if (to.name === 'Login') {
    if (store.getters['user/isLoggedIn']) {
      next({
        path: '/team',
      });
    } else {
      document.querySelector('html').className = 'login-page';
    }
  }

  // requires permission - use the helper to see whether the user can access it
  if (to.matched.some(record => record.meta.permissions)) {
    if (!PermissionsHelper.userHasPermission(to.meta.permissions)) {
      next({
        path: '/login',
        query: {
          nextUrl: to.name,
        },
      });
    }
  }

  next();
});

bus.$on('apiLogout', () => {
  router.push({name: 'Logout'}).then(() => Promise.reject());
});

export default router;
