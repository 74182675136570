/**
 * IDs for the integration types that match up with the TapAPI
 */
const DATA_SOURCE = {
  AD_ROLL: 106,
  BING_ADS: 36,
  FACEBOOK_ADS: 39,
  FACEBOOK_INSIGHTS: 44,
  GOOGLE_ADS: 3,
  GOOGLE_ANALYTICS: 1,
  HUBSPOT_ANALYTICS: 11,
  HUBSPOT: 14,
  LINKEDIN_ADS: 194,
  LINKEDIN_PAGES: 5,
  MAILCHIMP: 16,
  SALESFORCE_MARKETING: 9,
  SEARCH_ADS_360: 13,
  SNAPCHAT_ADS: 230,
  TWITTER_ADS: 113,
  INSTAGRAM_ANALYTICS: 15,
  STRIPE: 17,
  PINTEREST_ADS: 6,
  TWILIO: 89,
  CALL_RAIL: 115,
  GOOGLE_ANALYTICS_4: 19, // Live MS Connector Id
  SHOPIFY: 25, // Live MS Connector Id
  SPOTIFY_ADS: 29, // Live MS Connector Id
  DV360: 12, // Live MS Connector Id
  ADOBE_ANALYTICS: 24,
  TIKTOK_ADS: 31, // Live MS Connector Id
};

const CONNECTOR_SORT_ORDER = {
  "facebook-ads": 1,
  "linkedin-ads": 2,
  "facebook-insights": 3,
  "bing-ads": 4,
  "snapchat-ads": 5,
  "hubspot-analytics": 6
}

const PRICING_MODELS = {
  PRICING_MODEL_TIERED: "tiered",
  PRICING_MODEL_INDIVIDUAL: "individual",
};

const MODULES = {
  MODULE_FEATURE: "feature",
  MODULE_CONNECTOR: "connector",
};

const gdsConstants = {
  BUSINESS_UNITS: ["MEGALYTIC_GDS", "RAVEN_GDS", "TAPCLICKS_GDS"],
  DEFAULT_BUSINESS_UNIT: "MEGALYTIC_GDS",
  PRICING_MODELS: [PRICING_MODELS.PRICING_MODEL_TIERED, PRICING_MODELS.PRICING_MODEL_INDIVIDUAL],
  DEFAULT_PRICING_MODEL: PRICING_MODELS.PRICING_MODEL_INDIVIDUAL,
  MODULES: [MODULES.MODULE_FEATURE, MODULES.MODULE_CONNECTOR],
  INVALID_FORMULAE: ["SUM", "AVG", "N/A", "undefined", "masked"],
  CURRENCY_PREFIX: "CURRENCY_",
};

/**
 * Name of header used to pass business unit to API
 * @type {string}
 */
const BU_HEADER = "x-gds-businessUnit";

/**
 * Different business units that can be passed through to the API
 */
const BU_IMPLEMENTATIONS = {
  BU_DEFAULT: "MEGALYTIC_GDS",
  BU_MEGALYTIC: "MEGALYTIC_GDS",
  BU_RAVEN: "RAVEN_GDS",
  BU_TAPCLICKS: "TAPCLICKS_GDS"
};

const BU_PRICING_MODELS = {
  [BU_IMPLEMENTATIONS.BU_MEGALYTIC]: PRICING_MODELS.PRICING_MODEL_INDIVIDUAL,
  [BU_IMPLEMENTATIONS.BU_RAVEN]: PRICING_MODELS.PRICING_MODEL_INDIVIDUAL,
  [BU_IMPLEMENTATIONS.BU_TAPCLICKS]: PRICING_MODELS.PRICING_MODEL_INDIVIDUAL,
};

/**
 * Legible names for the business Units (used in filters in the admin panel)
 */
const BU_NAMES = {
  MEGALYTIC_GDS: "Megalytic",
  RAVEN_GDS: "Raven",
  TAPCLICKS_GDS: "TapClicks"
};

const ENVIRONMENTS = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  PATCH: 'patch'
};

/**
 * The CRM services that are currently configured for use in environments
 */
const CRM_SERVICES = {
  CRM_HUBSPOT: "HUBSPOT",
  CRM_NO_CRM: "NO_CRM"
};

const BASIC_ADDONS = ["accounts", "team-users"];

const COUPONS = {
  PROMO: "JOY50",
  ANNUAL: "ANNUALDISCOUNT",
  MEGA_50_OFF_ANNUAL: "MEGALYTIC_50_OFF_ANNUAL_DISCOUNT",
  MEGA_50_OFF_MONTHLY: "MEGALYTIC_50_OFF_MONTHLY_DISCOUNT",
  ONE_FREE_MONTH: "1CONOFF",
  THREE_FREE_MONTH: "3CONOFF",
  ONE_FREE_YEAR: "ANNUAL1CONOFF",
  THREE_FREE_YEAR: "ANNUAL3CONOFF",
};

const PLANS = {
  PLAN_ANNUAL: "annual-plan",
  PLAN_BASIC: "basic-plan",
  [BU_IMPLEMENTATIONS.BU_RAVEN]: {
    "basic-plan": {
      icon: "puzzle-piece",
      sortOrder: 0,
      price: 2999,
    },
    "annual-plan": {
      icon: "puzzle-piece",
      sortOrder: 0,
      price: 35988,
    },
    "starter-tier": {
      icon: "bicycle",
      sortOrder: 1,
    },
    "annual_starter-tier": {
      icon: "bicycle",
      sortOrder: 1,
    },
    "pro-tier": {
      icon: "motorcycle",
      sortOrder: 2,
    },
    "annual_pro-tier": {
      icon: "motorcycle",
      sortOrder: 2,
    },
  },
};

const ACCOUNT_STATUS = {
  ACTIVE: "active",
  IN_TRIAL: "in_trial",
  CANCELLED: "cancelled",
  TRIAL_EXPIRED: "trial_expired",
  NON_RENEWING: "non_renewing",
};

const ACCOUNTS_USED_KEY = "accountsUsed"; // cache key for accountsUsed in team cache

const FRONT_END_CONFIG_VALUES = [
  "GOOGLE_CLIENT_ID",
  "AMCHART_LICENSE",
  "CHARGEBEE_SITE",
];

const HTML_FIELDS = {
  [DATA_SOURCE.FACEBOOK_ADS]: ['adPreview'],
  [DATA_SOURCE.FACEBOOK_INSIGHTS]: ['postFullPicture'],
  [DATA_SOURCE.LINKEDIN_ADS]: ['creative'],
  [DATA_SOURCE.SEARCH_ADS_360]: ['adPreview'],
  [DATA_SOURCE.GOOGLE_ADS]: ['adType']
};

const PREVIEW_FIELDS = {
  [DATA_SOURCE.FACEBOOK_ADS]: ['adPreview'],
  [DATA_SOURCE.FACEBOOK_INSIGHTS]: ['postFullPicture'],
  [DATA_SOURCE.LINKEDIN_ADS]: ['creative'],
  [DATA_SOURCE.SEARCH_ADS_360]: ['adPreview'],
  [DATA_SOURCE.GOOGLE_ADS]: ['adType']
};

// making sure users can't select the deprecated connectors
const DEPRECATED_CONNECTORS = [
  "apex-chat",
  "avanser",
  "call-tracking-metrics"
];

const SUPPORTED_DATAVIEWS = {
  [DATA_SOURCE.LINKEDIN_PAGES]: {
    followerLifetimeStatistics: ["followersByStaffCount", "followersByAssociationType", "followersBySeniority", "followersByIndustry", "followersByCountry", "followersByRegion", "followersByJobFunction"],
    pageLifetimeStatistics: ["pageStatsByStaffCount", "pageStatsByRegion", "pageStatsByCountry", "pageStatsBySeniority", "pageStatsByFunction", "pageStatsByIndustry"],
    followerStatistics: ["followerStatistics"],
    shareStatistics: ["shareStatistics"],
    pageStatistics: ["pageStatistics"],
    shareLifetimeStatistics: ["cgn"]
  },
  [DATA_SOURCE.STRIPE]: {
    accountsBusinessProfileName: ["accountsBusinessProfileName"],
    customersName: ["customersName"],
    couponsName: ["couponsName"],
    applicationFeeId: ["applicationFeeId"],
    balanceType: ["balanceType"],
    balanceTransactionsId: ["balanceTransactionsId"],
    chargesId: ["chargesId"],
    transfersId: ["transfersId"],
    invoicesNumber: ["invoicesNumber"],
    ordersId: ["ordersId"],
    subscriptionsId: ["subscriptionsId"],
    subscriptionsPlansNickname: ["subscriptionsPlansNickname"],
    payoutsDescription: ["payoutsDescription"],
    refundsId: ["refundsId"],
    disputeId: ["disputeId"],
    reviewsId: ["reviewsId"],
    sourcesDataId: ["sourcesDataId"]
  },
  [DATA_SOURCE.MAILCHIMP]: {
    automationTitle: ["automationTitle"],
    cgn: ["cgn"],
    listName: ["listName"],
    timeSeriesTimestamp: ["timeSeriesTimestamp"],
    timeWarpGmtOffset: ["timeWarpGmtOffset"]
  },
  [DATA_SOURCE.HUBSPOT]: {
    contacts: ["contacts"],
    companies: ["companies"],
    deals: ["deals"],
    tickets: ["tickets"],
    emailEvents: ["emailEvents"],
    marketingEmails: ["marketingEmails"],
    forms: ["forms"],
    blogs: ["blogs"],
    engagements: ["engagements"],
    workflows: ["workflows"],
    contactsStage: ["contactsStage"],
  },
  [DATA_SOURCE.HUBSPOT_ANALYTICS]: {
    analyticsSource: ['analyticsSource'],
    analyticsGeoLocation: ['analyticsGeoLocation'],
    analyticsUtmMediums: ['analyticsUtmMediums'],
    analyticsUtmSources: ['analyticsUtmSources'],
    analyticsUtmTerms: ['analyticsUtmTerms'],
    analyticsUtmContents: ['analyticsUtmContents'],
    cgn: ['cgn'],
  },
  [DATA_SOURCE.SALESFORCE_MARKETING]: {
    accountName: ['accountName'],
    cgn: ['cgn'],
    contactName: ['contactName'],
    leadName: ['leadName'],
    opportunityName: ['opportunityName'],
    organizationName: ['organizationName'],
    taskDescription: ['taskDescription'],
    userName: ['userName'],
  },
};

const QUERY_TYPE_SUPPORTED_CONNECTORS = [
  DATA_SOURCE.LINKEDIN_PAGES,
  DATA_SOURCE.STRIPE,
  DATA_SOURCE.MAILCHIMP,
  DATA_SOURCE.HUBSPOT,
  DATA_SOURCE.HUBSPOT_ANALYTICS,
  DATA_SOURCE.SALESFORCE_MARKETING,
];

const LIVE_API_ENVIRONMENTS = {
  PATCH: "patch",
  UAT: "uat",
  QA: "qa"
}

const LIVE_API_ENV_CONFIG = {
  [LIVE_API_ENVIRONMENTS.UAT]: {
    liveApiKey: "c28f5d92-8e05",
    liveSchemaUrl: "https://live-uat.tapclicks.net/connectors",
    liveJobIdUrl: "https://live-uat.tapclicks.net/data",
    liveGetDataUrl: "https://live-uat.tapclicks.net/poll"
  },
  [LIVE_API_ENVIRONMENTS.PATCH]: {
    liveApiKey: "c28f5d92-8e05",
    liveSchemaUrl: "https://live-patch.tapclicks.net/connectors",
    liveJobIdUrl: "https://live-patch.tapclicks.net/data",
    liveGetDataUrl: "https://live-patch.tapclicks.net/poll"
  },
  [LIVE_API_ENVIRONMENTS.QA]: {
    liveApiKey: "c28f5d92-8e05",
    liveSchemaUrl: "https://live-qa.tapclicks.net/connectors",
    liveJobIdUrl: "https://live-qa.tapclicks.net/data",
    liveGetDataUrl: "https://live-qa.tapclicks.net/poll"
  }
}

module.exports = {
  gdsConstants,
  ENVIRONMENTS,
  BU_HEADER,
  BU_IMPLEMENTATIONS,
  BU_NAMES,
  PRICING_MODELS,
  CRM_SERVICES,
  BASIC_ADDONS,
  COUPONS,
  PLANS,
  BU_PRICING_MODELS,
  ACCOUNT_STATUS,
  MODULES,
  ACCOUNTS_USED_KEY,
  FRONT_END_CONFIG_VALUES,
  HTML_FIELDS,
  DEPRECATED_CONNECTORS,
  PREVIEW_FIELDS,
  DATA_SOURCE,
  CONNECTOR_SORT_ORDER,
  QUERY_TYPE_SUPPORTED_CONNECTORS,
  SUPPORTED_DATAVIEWS,
  LIVE_API_ENVIRONMENTS,
  LIVE_API_ENV_CONFIG,
};
