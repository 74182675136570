import axiosClient from '../../services/axiosClient';

/**
 * Handles API communications with the server app
 * related to Auth
 */
const AuthAPI = {
  authExternally(authOptions) {
    const params = Object.keys(authOptions).map(key => `${key}=${encodeURIComponent(authOptions[key])}`).join('&');

    return axiosClient
      .get(`/api/auth/?${params}`);
  },
  auth(integrationID, email, businessUnit) {
    return axiosClient
      .get(`/api/auth/?integration_type=${integrationID}&masterEmail=${email}&type=reauth&business_unit=${businessUnit}`);
  },
  callback(stateOptions) {
    const params = Object.keys(stateOptions).map(key => `${key}=${encodeURIComponent(stateOptions[key])}`).join('&');
    return axiosClient
      .get(`/api/auth/callback?${params}`);
  },
};

export default AuthAPI;
