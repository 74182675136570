<template>
  <div
    id="app"
    :class="getFrontEndSkinBusinessUnit()"
  >
    <Layout v-if="shouldDisplayLayout()" />
    <div
      :class="{ 'columns': shouldDisplayLayout() }"
    >
      <div
        :class="{ 'column is-one-fifth': shouldDisplayLayout() }"
      />

      <section
        :class="{ 'column': shouldDisplayLayout() }"
      >
        <router-view />
      </section>

      <div
        :class="{ 'column is-one-fifth': shouldDisplayLayout() }"
      />
    </div>

    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Layout from './Layout';
import FrontEndSkinHelper from './services/FrontEndSkinHelper';

const { favicon } = FrontEndSkinHelper.getWebsiteSkin();

export default {
  name: 'App',
  components: {
    Layout,
  },
  computed: {
    isLoading() {
      return this.getLoadingState();
    },
    ...mapState(['user']),
  },
  mounted() {
    const $appLoader = document.getElementById('app-loader');
    if ($appLoader) {
      $appLoader.remove();
    }

    // frontend defines the favicon
    const faviconElement = document.querySelector('link[rel="icon"]');
    faviconElement.href = `/${favicon}`;

    console.info(`GDS front-end version ${this.getVersion()}`);
  },
  methods: {
    shouldDisplayLayout() {
      if (!this.user.loggedIn) {
        return false;
      }

      if (this.$route.name === 'Logout') {
        return false;
      }

      return !this.$route.meta.isCallback;
    },
    ...mapGetters('app', ['getLoadingState', 'getVersion']),
  },
};
</script>
