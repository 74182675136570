import axios from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
import { bus } from './bus';
import FrontEndSkinHelper from './FrontEndSkinHelper';

const constants = require('../../../server/src/config/constants');

const { businessUnit } = FrontEndSkinHelper.getWebsiteSkin();

const apiClient = axios.create({
  baseURL: "/",
  withCredentials: true,
  headers: {
    post: {
      'Content-Type': 'application/x-www-form-urlencoded',
      [constants.BU_HEADER]: businessUnit,
    },
    put: {
      'Content-Type': 'application/x-www-form-urlencoded',
      [constants.BU_HEADER]: businessUnit,
    },
    get: {
      [constants.BU_HEADER]: businessUnit,
    },
  },
});

const redirectStatuses = [
  401,
  440,
];

// intercept any logout redirects
apiClient.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (
    error.response &&
    redirectStatuses.includes(error.response.status)
  ) {
    Toast.open({
      duration: 2000,
      message: 'You have been logged out due to inactivity.  Please log-in again',
      type: 'is-primary',
      queue: false,
    });
    bus.$emit('apiLogout');
    return Promise.reject(error);
  }

  return Promise.reject(error);
});

export default apiClient;
