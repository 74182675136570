import axiosClient from '../../services/axiosClient';

/**
 * Handles API communications with the server app
 * related to Connectors
 */
const ConnectorsAPI = {
  getConnectors(all = false) {
    let url = '/api/dataSources/connectorConfig';
    if (all) {
      url += '?all=true';
    }

    return axiosClient
      .get(url);
  },
  getConfig() {
    return axiosClient
      .get('/api/home/config');
  },
};

export default ConnectorsAPI;
