import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import AuthAPI from '../api/modules/Auth';
import BaseAuth from '../components/auth/BaseAuth';
import ConnectorMixin from './ConnectorMixin';

const AddDataSourceMixin = {
  methods: {
    addNewConnector(connectorId) {
      this.setLoadingState(true);
      AuthAPI.auth(connectorId, this.getEmail(), this.getFrontEndSkinBusinessUnit())
        .then((res) => {
          this.setLoadingState(false);

          const connector = ConnectorMixin.methods.getConnectorById(connectorId);
          if (typeof connector.loginFields !== 'undefined') {
            Vue.prototype.addModal({
              parent: this,
              component: BaseAuth,
              hasModalCard: true,
              canCancel: true,
              props: {
                internalConnectorId: Number(connectorId),
                authURI: res.data.authUri,
                credentials: res.data.credentials,
                gdsRedirectUri: res.data.gdsRedirectUri,
              },
            });
          } else if (typeof res.data.error === 'undefined') {
            // forward straight through to the oAuth screen
            window.location.assign(res.data.authUri);
          } else {
            this.$buefy.toast.open({
              message: `Could not make a connection: ${res.data.error}`,
              type: 'is-danger',
            });
          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
          this.$buefy.toast.open({
            message: 'Could not make a connection.  Please try again',
            type: 'is-danger',
          });

          this.setLoadingState(false);
        });
    },
    ...mapActions('app', ['setLoadingState']),
    ...mapGetters('user', ['getEmail']),
  }
};

export default AddDataSourceMixin;
