import Vue from 'vue';
import BillingAPI from '../../api/modules/Billing';
import { STATUSES, PRICING_MODELS } from '../../../config/Constants';

/**
 * vuex store representing a billing instance
 */
const billingState = () => ({
  data: {
    plan: {},
    addons: [],
    invoices: [],
    payments: {},
    pricingModel: undefined,
    pricingPlans: [],
    customAddons: [],
  },
});

export default {
  namespaced: true,
  state: billingState,
  getters: {
    getAddons: state => state.data.addons,
    getUserLimit: state => state.data.plan.userLimit,
    getAccountLimit: state => state.data.plan.accountLimit,
    getConnectors: state => state.data.plan.connectors,
    getPlanStatus: state => state.data.plan.subscriptionDetails.status,
    isApplyDiscount: state => state.data.plan.subscriptionDetails.applyDiscount,
    getApplicableDiscounts: state => state.data.plan.applicableDiscounts,
    getWebhookStatus: state => state.data.plan.subscriptionDetails.webhook_status,
    planIsOnTrial: state => typeof state.data.plan.subscriptionDetails.status !== 'undefined' &&
      state.data.plan.subscriptionDetails.status === STATUSES.STATUS_ON_TRIAL,
    getPlanId: state => state.data.plan.subscriptionDetails.planId,
    isAnnualPlan: state => state.data.plan.subscriptionDetails.period !== 'month',
    getEndDate: state => typeof state.data.plan.subscriptionDetails !== 'undefined' ?
      state.data.plan.subscriptionDetails.end_date : null,
    getTrialAddon: state => state.data.plan.subscriptionDetails.trialAddon,
    getCurrentPlan: state => {
      if (typeof state.data.pricingPlans[state.data.plan.subscriptionDetails.period] !== 'undefined') {
        for (const plan of state.data.pricingPlans[state.data.plan.subscriptionDetails.period]) {
          if (String(state.data.plan.subscriptionDetails.planId) === String(plan.planId)) {
            return plan;
          }
        }
      }
      return null;
    },
    getInvoices: state => state.data.invoices,
    getMostRecentInvoice: state => {
      if (typeof state.data.invoices[0] !== 'undefined') {
        return state.data.invoices[0];
      }

      return {};
    },
    getPayments: state => state.data.payments,
    getPlan: state => state.data.plan,
    getPricingModel: state => state.data.pricingModel,
    getPricingTierPlans: state => state.data.pricingPlans,
    isTieredPricingModel: state => state.data.pricingModel === PRICING_MODELS.PRICING_MODEL_TIERED,
    isIndividualPricingModel: state => state.data.pricingModel === PRICING_MODELS.PRICING_MODEL_INDIVIDUAL,
    isFeedbackSubmitted: state => state.data.plan.isFeedbackSubmitted,
  },
  actions: {
    getBillingInfo({state, dispatch}, force = false) {
      return new Promise((resolve) => {
        if (
          (Object.entries(state.data.plan).length === 0 &&
          state.data.plan.constructor === Object) ||
          force
        ) {
          dispatch('getAPIPlanUsage').then((res) => {
            resolve(res);
          });
        } else {
          resolve(state.data.plan);
        }
      });
    },
    getAPIPlanUsage: ({ commit }) => {
      return new Promise((resolve) => {
        BillingAPI.usage()
          .then((res) => {
            commit('setUsageInState', res.data);
            resolve(res.data);
          });
      });
    },
    getPlans({state, dispatch}, force = false) {
      return new Promise((resolve) => {
        if (state.data.pricingPlans.length === 0 || force) {
          dispatch('getAPIPlans').then((res) => {
            resolve(res);
          });
        } else {
          resolve(state.data.pricingPlans);
        }
      });
    },
    getAPIPlans: ({ commit }) => {
      return new Promise((resolve) => {
        BillingAPI.plans()
          .then((res) => {
            commit('setPlansInState', res.data);
            resolve(res.data);
          });
      });
    },
    pullAddons({state, dispatch}, force = false) {
      return new Promise((resolve) => {
        if (
          (Object.entries(state.data.addons).length === 0 &&
          state.data.addons.constructor === Array) ||
          force
        ) {
          dispatch('getAPIAddons').then((res) => {
            resolve(res);
          });
        } else {
          resolve(state.data.addons);
        }
      });
    },
    getAPIAddons: ({ commit }) => {
      return new Promise((resolve) => {
        BillingAPI.addons()
          .then((res) => {
            commit('setAddonsInState', res.data);
            resolve(res.data);
          });
      });
    },
    pullInvoices({state, dispatch}, force = false) {
      return new Promise((resolve) => {
        if (
          (Object.entries(state.data.invoices).length === 0 &&
          state.data.invoices.constructor === Array) ||
          force
        ) {
          dispatch('getAPIInvoices').then((res) => {
            resolve(res);
          });
        } else {
          resolve(state.data.invoices);
        }
      });
    },
    getAPIInvoices: ({ commit }) => {
      return new Promise((resolve) => {
        BillingAPI.invoices()
          .then((res) => {
            commit('setInvoicesInState', res.data);
            resolve(res.data);
          });
      });
    },
    clearPlan: ({ commit }) => {
      commit('clearPlan');
    },
    clearPlans: ({ commit }) => {
      commit('clearPlans');
    },
    clearInvoices: ({ commit }) => {
      commit('clearInvoices');
    },
    clearAddons: ({ commit }) => {
      commit('clearAddons');
    },
  },
  mutations: {
    setInvoicesInState(state, invoices) {
      Vue.set(state.data, 'invoices', invoices);
    },
    setAddonsInState(state, addons) {
      Vue.set(state.data, 'addons', addons.addons);
    },
    setUsageInState(state, plan) {
      Vue.set(state.data, 'plan', plan);
      Vue.set(state.data, 'pricingModel', plan.pricingModel);
    },
    setPlansInState(state, data) {
      Vue.set(state.data, 'pricingPlans', data.plans);
    },
    clearAddons(state) {
      Vue.set(state.data, 'addons', []);
    },
    clearPlan(state) {
      Vue.set(state.data, 'plan', {});
      Vue.set(state.data, 'pricingModel', {});
    },
    clearPlans(state) {
      Vue.set(state.data, 'pricingPlans', []);
    },
    clearInvoices(state) {
      Vue.set(state.data, 'invoices', []);
    },
  },
};
