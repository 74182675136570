import axiosClient from '../../services/axiosClient';

/**
 * Handles API communications with the server app
 * related to roles - get / edit / add / delete
 */
const RoleAPI = {
  getRoles() {
    return axiosClient
      .get('/api/team/roles');
  },
  roleAction(roleData) {
    if (roleData.isEdit) {
      return this.editRole(roleData);
    }

    return this.createRole(roleData);
  },
  async getRole(roleId) {
    return axiosClient
      .get(`/api/team/role?role_id=${roleId}`);
  },
  createRole(roleData) {
    return axiosClient
      .post('/api/team/role', roleData);
  },
  editRole(roleData) {
    return axiosClient
      .put('/api/team/role', roleData);
  },
  deleteRole(roleId) {
    return axiosClient
      .delete(`/api/team/role?id=${roleId}`);
  },
};

export default RoleAPI;
