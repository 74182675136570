import { mapActions } from 'vuex';

const LoadingStateMixin = {
  methods: {
    setAppLoadingState(loadingState) {
      this.setLoadingState(loadingState);
    },
    ...mapActions('app', ['setLoadingState']),
  },
};

export default LoadingStateMixin;
