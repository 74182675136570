<template>
  <section>
    <div
      v-if="isLoginForm()"
    >
      <header class="modal-card-head">
        <p class="modal-card-title">
          Login to {{ connectorName }}
        </p>
      </header>

      <form
        @submit.prevent="loginWithForm()"
      >
        <section class="modal-card-body">
          <b-field
            v-for="field in connector.loginFields"
            :key="field.fieldName"
            :label="field.label"
          >
            <b-input
              v-model="field.value"
              :type="field.type"
              required
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            type="is-info"
            native-type="submit"
          >
            Login with {{ connectorName }}
          </b-button>
        </footer>
      </form>
    </div>
    <div
      v-else
      class="columns is-centered margin-top-2-rem"
    >
      <div class="column is-half has-text-centered">
        <p>
          Make sure you are logged in with the correct {{ connectorName }} account or go to
          <a
            :href="connectorURL"
            target="_blank"
          >
            {{ connectorName }}
          </a> and log out.
        </p>

        <p class="margin-top-1-rem">
          <b-button
            v-if="!isAGoogleConnector()"
            tag="a"
            :href="authURI"
            class="is-info button-shadowed is-outlined margin-top-10"
          >
            Log in with {{ connectorName }}
          </b-button>
          <a
            v-else
            :href="authURI"
            title="Sign in with Google"
          >
            <img
              src="@/assets/img/google-sign-in.png"
              alt="Sign-in with Google"
            >
          </a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import DataSourceAPI from '../../api/modules/DataSource';
import { bus } from '../../services/bus';

export default {
  name: 'AuthLogin',
  props: {
    internalConnectorId: {
      type: Number,
      required: true,
    },
    authURI: {
      type: String,
      required: true,
    },
    connectorName: {
      type: String,
      required: true,
    },
    connectorURL: {
      type: String,
      required: true,
    },
    gdsRedirectUri: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
    isPublic: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      connector: {
        nameSimple: '',
        url: '',
      },
    };
  },
  created() {
    Vue.set(this, 'connector', this.getConnectorById(this.internalConnectorId, this.isPublic));
  },
  methods: {
    isAGoogleConnector() {
      return [1, 3, 12, 13, 19].includes(this.internalConnectorId);
    },
    isLoginForm() {
      return typeof this.connector.loginFields !== 'undefined';
    },
    loginWithForm() {
      this.setAppLoadingState(true);

      const dataFields = {};
      for (const formItem of this.connector.loginFields) {
        dataFields[formItem.fieldName] = formItem.value;
      }

      dataFields.state = this.authURI;

      DataSourceAPI.authCallbackLogin(dataFields).then((res) => {
        if (res.data.success) {
          if (this.gdsRedirectUri) {
            window.location.href = this.gdsRedirectUri;
          } else {
            this.$buefy.toast.open({
              message: `Your ${this.connector.name} account was connected`,
              type: 'is-success',
            });

            this.closeWindow();
          }
        } else {
          this.$buefy.toast.open({
            message: `Your ${this.connector.name} account was not valid`,
            type: 'is-danger',
          });
        }
      })
      .catch(() => {
        this.$buefy.toast.open({
          message: `Your ${this.connector.name} account was not valid`,
          type: 'is-danger',
        });
      })
      .finally(() => {
        this.setAppLoadingState(false);
        this.closeWindow();
      });
    },
    closeWindow() {
      bus.$emit('dataSourcesUpdated');

      // eslint-disable-next-line this is nested as part of BaseAuth which is itself loaded in the modal
      this.$parent.$parent.close();
    },
    ...mapActions('dataSource', ['getDataSources']),
  },
};
</script>
