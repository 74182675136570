import axiosClient from '../../services/axiosClient';

/**
 * Handles API communications with the server app
 * related to Billing (plan, invoices, etc)
 */
const BillingAPI = {
  usage() {
    return axiosClient
      .get('/api/billing/planUsage');
  },
  updateSubscriptionWebhookStatus(status) {
    return axiosClient.post('/api/billing/updateSubscriptionWebhookStatus', {
      webhookStatus: status
    });
  },
  cancelSubscription() {
    return axiosClient
      .post('/api/billing/cancelSubscription');
  },
  getReasonsForChurn() {
    return axiosClient
      .get('/api/billing/churnReasons');
  },
  churnFeedback(payload) {
    return axiosClient
      .post('/api/billing/churnFeedback', { payload });
  },
  feedback(payload) {
    return axiosClient
      .post('/api/billing/feedback', { payload });
  },
  addons() {
    return axiosClient
      .get('/api/billing/addons');
  },
  plans() {
    return axiosClient
      .get('/api/billing/plans');
  },
  estimate(addOns) {
    return axiosClient
      .post('/api/billing/estimate', addOns);
  },
  estimateNextBillingCycle() {
    return axiosClient
      .post('/api/billing/estimate/nextBillingAmount', { addons:{} });
  },
  async customise(addOns) {
    return axiosClient
      .post('/api/billing/customize', addOns);
  },
  async updateCard() {
    return axiosClient
      .post('/api/billing/updateCard');
  },
  invoices() {
    return axiosClient
      .get('/api/billing/invoices');
  },
  downloadInvoice(id) {
    return axiosClient
      .post('/api/billing/invoice/download', {id});
  },
};

export default BillingAPI;
