class FrontEndSkin {
  constructor(params) {
    this.class = params.class;
    this.name = params.name;
    this.url = params.url;
    this.enterpriseEmail = params.enterpriseEmail;
    this.logo = params.logo;
    this.primaryColor = params.primaryColor;
    this.loginBackground = params.loginBackground;
    this.seriesColors = params.seriesColors && params.seriesColors;
    this.businessUnit = params.businessUnit;
    this.planOptions = params.plan;
    this.favicon = params.favicon;
    this.supportUrl = params.supportUrl;
    this.faqUrl = params.faqUrl;
    this.supportEmail = params.supportEmail;
    this.loginBackgroundImage = params.loginBackgroundImage;
    this.dataSourceInterestForm = params.dataSourceInterestForm;
    this.gTagId = params.gTagId;
  }

  hasBackgroundColor() {
    return this.loginBackground[0] === '#';
  }

  hasBackgroundImage() {
    return this.loginBackgroundImage;
  }
}

export default FrontEndSkin;
