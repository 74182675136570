import Vue from 'vue';
import ConnectorsAPI from '../../api/modules/Connectors';

/**
 * vuex store representing a connector config instance
 */
const connectorsState = () => ({
  data: {
    all: [],
    allowed: [],
    config: {},
  },
});

function manageConnectors(connectors) {
  const returnConnectors = [];

  for (const kindId of Object.keys(connectors)) {
    const connector = connectors[kindId];
    connector.id = kindId;

    returnConnectors.push(connector);
  }

  return returnConnectors.sort((a, b) => a.name.localeCompare(b.name, 'en', {'sensitivity': 'base'}));
}

export default {
  namespaced: true,
  state: connectorsState,
  getters: {
    getAllFromState: state => state.data.all,
    getAllowedFromState: state => state.data.allowed,
    configIsEmpty: state => Object.entries(state.data.config).length === 0,
    getConfig: state => state.data.config,
  },
  actions: {
    pullAllConnectors({ commit, dispatch }) {
      dispatch('pullAllowedConnectors');

      return new Promise(resolve => {
        ConnectorsAPI.getConnectors(true)
        .then((res) => {
          const connectors = manageConnectors(res.data);

          commit('setAllConnectorsInState', connectors);
          resolve();
        });
      });
    },
    pullAllowedConnectors({ commit }) {
      ConnectorsAPI.getConnectors(false)
        .then((res) => {
          const connectors = manageConnectors(res.data);

          commit('setAllowedConnectorsInState', connectors);
        });
    },
    pullConfig({ commit }) {
      return ConnectorsAPI.getConfig()
        .then((res) => {
          commit('setConfigInState', res.data.config);

          return res.data.config;
        });
    },
    clearConnectors: ({ commit }) => {
      commit('clearConnectors');
    },
  },
  mutations: {
    setAllConnectorsInState(state, connectors) {
      Vue.set(state.data, 'all', connectors);
    },
    setAllowedConnectorsInState(state, connectors) {
      Vue.set(state.data, 'allowed', connectors);
    },
    setConfigInState(state, config) {
      Vue.set(state.data, 'config', config);
    },
    clearConnectors(state) {
      Vue.set(state.data, 'all', []);
      Vue.set(state.data, 'allowed', []);
      Vue.set(state.data, 'config', {});
    },
  }
};
