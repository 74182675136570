import Vue from 'vue';
import moment from 'moment';

Vue.filter('humanDate', function(value) {
  if (value) {
    try {
      return moment(String(value)).format('ll');
    } catch {
      // if it fails to parse it's probably coming from chargebee in a non-standard format:
      return moment(String(value), 'YYYY-M-D').format('ll');
    }
  }
});

Vue.filter('chargebeeAmount', function(value) {
  if (value || value === 0) {
    return value / 100;
  }
});
