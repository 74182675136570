import axiosClient from '../../services/axiosClient';

/**
 * Handles API communications with the server app
 * related to DataSources
 */
const DataSourceAPI = {
  getSources() {
    return axiosClient
      .get('/api/dataSources/groupedData');
  },
  deleteAccount(accountId) {
    return axiosClient
      .delete(`/api/dataSources/deleteAccount/${accountId}`);
  },
  restoreAccount(accountId) {
    return axiosClient
      .post(`/api/dataSources/restoreAccount/${accountId}`);
  },
  authCallbackLogin(loginData) {
    return axiosClient
      .post('/api/auth/callback', loginData);
  }
};

export default DataSourceAPI;
